export const CREATE_POST = 'POST/CREATE_POST'
export const FETCH_POSTS = 'POST/FETCH_POSTS'
export const REQUEST_POSTS = 'POST/REQUEST_POSTS'

export const SHOW_LOADER = 'APP/SHOW_LOADER'
export const HIDE_LOADER = 'APP/HIDE_LOADER'
export const SHOW_ALERT = 'APP/SHOW_ALERT'
export const HIDE_ALERT = 'APP/SHOW_ALERT'


